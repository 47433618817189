<script setup lang="ts">
import {useElementor, useWp} from "~/composables/Redmonkey";
import ListAll from "~/components/Redmonkey/Events/ListAll.vue";
import ListShort from "~/components/Redmonkey/Events/ListShort.vue";
import type {WP} from "~/integration/wordpress/types";
import type {ComponentPublicInstance} from "@vue/runtime-core";
import {NuxtLink} from "#components";
import TitleBase from "~/components/Redmonkey/Base/TitleBase.vue";
import {useSettingsStore} from "~/stores/settings";

const settingsStore = useSettingsStore();

let Element = ListShort as ComponentPublicInstance | unknown;
let variables = {};

const props = defineProps({
  element: {
    type: Object,
    required: true
  }
})

const {$vwf} = useNuxtApp();

const {
  getSettingsValue,
  isLinkTargetBlank
} = useElementor();

const {
  isExternalLink,
  getSettingsValue: getThemeSettingValue
} = useWp();


if (getSettingsValue(props?.element, 'is_page')) {
  Element = ListAll;
}

const eventsSelected = getSettingsValue(props?.element, 'choose_events');
if (eventsSelected) {
  if (eventsSelected.length) {
    variables = {
      ids: eventsSelected.split(',')
    }
  }
}

const {data: events} = await useAsyncData(`events-${props.element.id}`, async () => {
  const {events} = await ($vwf as WP).$wordpress.api.getEvents(variables);
  return events;
});

const buttonUrl = getSettingsValue(props?.element, 'events_all_button_url') || '/events';

const button = {
  url: buttonUrl,
  target: isLinkTargetBlank('events_all_button_url') ? '_blank' : '_self',
  title: getSettingsValue(props?.element, 'events_all_button_text') || 'Більше заходів',
  component: isExternalLink(buttonUrl) ? 'a' : NuxtLink
}
</script>
<template>
  <div class="events-list">
    <TitleBase
        :title="getSettingsValue(element, 'title')"
        :position="getSettingsValue(element, 'is_page') ? 'left' : 'center'"
        :star-position="getSettingsValue(element, 'is_page') ? 'right' : 'left'"
    />

    <component
        :is="Element"
        :events="events"
        :button="button"
        :white-logo="getThemeSettingValue(settingsStore.redmonkeySettings, 'node', 'whiteLogo')"
    />
  </div>
</template>

<style lang="scss">
  @import "assets/css/source/components/widgets/events";
</style>