<script setup lang="ts">
import Item from "~/components/Redmonkey/Events/Item.vue";
import type {PropType} from "vue";
import type {WP, WpMedia} from "~/integration/wordpress/types";
import CategoryFilter from "~/components/Redmonkey/Filter/CategoryFilter.vue";
const {$vwf} = useNuxtApp();

const props = defineProps({
  events: {
    type: Object,
    required: true,
    default: {nodes: []}
  },
  button: {
    type: Object,
    default: {
      component: 'a',
      url: null,
      title: null,
      target: null
    }
  },
  whiteLogo: {
    type: Object as PropType<WpMedia>
  }
})

const eventsRef = ref(props.events);
const isNextPageExist = computed(() => eventsRef.value.pageInfo?.hasNextPage);
const activeCategoryNameFilter = ref('');


const updateEvents = async (params: object, merge = true) => {
  const {events} = await ($vwf as WP).$wordpress.api.getEvents(params);

  if (events?.nodes) {
    let nodes = events.nodes;

    if(merge) {
      nodes = [
        ...eventsRef.value.nodes,
        ...events.nodes
      ]
    }

    eventsRef.value = {
      nodes: nodes,
      pageInfo: events.pageInfo
    }
  }
}

const loadMore = async () => {
  if (!eventsRef.value?.pageInfo?.endCursor) return;

  await updateEvents({
    after: eventsRef.value.pageInfo.endCursor,
    categoryName: activeCategoryNameFilter.value
  });
}

const refetch =  async (categoryName = '') => {
  activeCategoryNameFilter.value = categoryName;
  await updateEvents({
    categoryName: activeCategoryNameFilter.value
  }, false)
}
</script>
<template>
  <div class="events-list events-full">
    <CategoryFilter :active-name="activeCategoryNameFilter" :fetch="refetch" type="events" />
    <div class="event-items">
      <template v-for="(event, index) in eventsRef.nodes" :key="event.id">
        <Item
            :name="event.title"
            :title="event.events.title"
            :sub-title="event.events.subTitle"
            :url="event.uri"
            :image="event.featuredImage.node"
            :white-logo="whiteLogo"
            :city="event.events.misto"
            :date-from="event.events.dateTimeFrom"
            :date-to="event.events.dateTimeTo"
            :price="event.events.price"
        />
      </template>
    </div>
    <div class="all-events-button" v-if="isNextPageExist">
      <a href="#" class="action primary" @click.prevent="loadMore()">
        {{ button.title }}
      </a>
    </div>
  </div>
</template>