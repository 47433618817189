<script setup lang="ts">
import Item from "~/components/Redmonkey/Events/Item.vue";
import type {PropType} from "vue";
import type {WpMedia} from "~/integration/wordpress/types";

const props = defineProps({
  events: {
    type: Object,
    required: true,
    default: {nodes: []}
  },
  button: {
    type: Object,
    default: {
      component: 'a',
      url: null,
      title: null,
      target: null
    }
  },
  whiteLogo: {
    type: Object as PropType<WpMedia>
  }
})

const isButton = () => {
  return props?.button?.url;
}
</script>
<template>
  <div class="events-list events-short">
    <div class="event-items">
      <template v-for="(event, index) in events.nodes" :key="event.id">
        <Item
            v-if="index < 3"
            :name="event.title"
            :title="event.events.title"
            :sub-title="event.events.subTitle"
            :url="event.uri"
            :image="event.featuredImage.node"
            :white-logo="whiteLogo"
            :city="event.events.misto"
            :date-from="event.events.dateTimeFrom"
            :date-to="event.events.dateTimeTo"
            :price="event.events.price"
        />
      </template>
    </div>
    <div class="all-events-button" v-if="isButton() && events.nodes.length > 3">
      <component class="action primary" :is="button.component" :to="button.url" :href="button.url"
                 :target="button.target">
        {{ button.title }}
      </component>
    </div>
  </div>
</template>